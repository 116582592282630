import { useEffect } from "react";
import { BuildTheme } from "../../assets/global/theme";

const ThemeSettings = () => {
  const theme = BuildTheme({
    direction: "ltr",
    theme: "RED_THEME",
  });
  useEffect(() => {
    document.dir = "ltr";
  }, []);

  return theme;
};
export default ThemeSettings;
