import React from "react";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Router from "./routes";
import { SnackbarProvider } from "notistack";
import ThemeSettings from "./wrapper/themeSettings";

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={6000}>
        <CssBaseline />
        {routing}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
