import { UPDATE_PROFILE } from "../../constants";

const INIT_STATE = {
  logged_in: false,
  info: {},
  token: "",
  role: "",
};

const ProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export default ProfileReducer;
