import React, { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<CircularProgress />}>
    <Component {...props} />
  </Suspense>
);

const AuthenticatedLayout = Loadable(
  lazy(() => import("../wrapper/authenticated"))
);
const UnauthenticatedLayout = Loadable(
  lazy(() => import("../wrapper/unauthenticated"))
);

const Error = Loadable(lazy(() => import("./error")));
const HomeNew = Loadable(lazy(() => import("./HomeNew")));
const Login = Loadable(lazy(() => import("./login")));
const Signup = Loadable(lazy(() => import("./signup")));
const OrganiseEvent = Loadable(lazy(() => import("./organise-event")));
const JoinEvent = Loadable(lazy(() => import("./join-event")));
const UploadEventMedia = Loadable(lazy(() => import("./upload-media")));
const ModerateMedia = Loadable(lazy(() => import("./moderate-media")));
const ModerateEvents = Loadable(lazy(() => import("./moderate-events")));
const ResetPassword = Loadable(lazy(() => import("./resetPassword")));
const EventsList = Loadable(lazy(() => import("./events-list")));
const JoinedEvents = Loadable(lazy(() => import("./joined-events")));
const EditEvent = Loadable(lazy(() => import("./edit-event")));
const Downloads = Loadable(lazy(() => import("./downloads")));
const Gallery = Loadable(lazy(() => import("./gallery")));
const About = Loadable(lazy(() => import("./about")));
const UpcomingWalks = Loadable(lazy(() => import("./upcoming-walks")));

const routes = [
  { path: "/", element: <HomeNew /> },
  { path: "/home-new", element: <HomeNew /> },
  { path: "/gallery", element: <Gallery /> },
  { path: "/about", element: <About /> },
  { path: "/upcoming-walks", element: <UpcomingWalks /> },
  {
    path: "/",
    element: <AuthenticatedLayout />,
    children: [
      { path: "organise-event", element: <OrganiseEvent /> },
      { path: "downloads", element: <Downloads /> },
      { path: "events-list", element: <EventsList /> },
      { path: "joined-events", element: <JoinedEvents /> },
      { path: "join-event", element: <JoinEvent /> },
      { path: "join-event/:id", element: <JoinEvent /> },
      { path: "edit-event/:id", element: <EditEvent /> },
      { path: "upload-media", element: <UploadEventMedia /> },
      { path: "moderate-media", element: <ModerateMedia /> },
      { path: "moderate-events", element: <ModerateEvents /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "auth",
    element: <UnauthenticatedLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "signup", element: <Signup /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default routes;
