import _ from "lodash";
import { createTheme } from "@mui/material/styles";
const TopbarHeight = 70;
const typography = {
  body1: {
    fontWeight: 400, // or 'bold'
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  h1: {
    fontWeight: 500,
    fontSize: "1.875rem",
    lineHeight: "1.5",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  h2: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "1.5",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  h3: {
    fontWeight: 500,
    fontSize: "1.3125rem",
    lineHeight: "1.5",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  h4: {
    fontWeight: 500,
    fontSize: "1.125rem",
    lineHeight: "1.5",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  h5: {
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.5",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.5",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  button: {
    textTransform: "none",
    fontWeight: "400",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  subtitle1: {
    fontSize: "1rem",
    fontWeight: "400",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
  subtitle2: {
    fontSize: "0.875rem",
    fontWeight: "400",
    fontFamily: "'walk4Dyslexia', sans-serif",
  },
};

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      "*": {
        boxSizing: "border-box",
      },
      html: {
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        margin: 0,
        padding: 0,
      },
      "#root": {
        height: "100%",
      },
      "*[dir='rtl'] .buyNowImg": {
        transform: "scaleX(-1)",
      },

      ".buyNowImg": {
        position: "absolute",
        right: "-44px",
        top: "-18px",
        width: "143px",
        height: "175px",
      },
      ".MuiCardHeader-action": {
        alignSelf: "center !important",
      },
      ".scrollbar-container": {
        borderRight: "0 !important",
      },
      "*[dir='rtl'] .welcomebg:before": {
        backgroundPosition: "top -24px left -9px !important",
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        paddingLeft: "15px !important",
        paddingRight: "15px !important",
        maxWidth: "1600px",
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        boxShadow: "none",
        fontSize: "15px",
        "&:hover": {
          boxShadow: "none",
        },
      },
    },
  },

  MuiListItem: {
    styleOverrides: {
      root: {
        borderRadius: "9px",
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "20px",
        padding: "14px",
        margin: "15px",
        boxShadow: "0px 7px 30px 0px rgba(90, 114, 123, 0.11)",
      },
    },
  },

  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "40px",
      },
    },
  },

  MuiGridItem: {
    styleOverrides: {
      root: {
        paddingTop: "30px",
        paddingLeft: "30px !important",
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        backgroundColor: "#ecf0f2",
        borderRadius: "6px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        borderRadius: "0",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        fontWeight: "500",
        fontSize: "0.75rem",
      },
    },
  },
};

const shadows = [
  "none",
  "0px 2px 3px rgba(0,0,0,0.10)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)",
  "1px 2px 10px rgba(0,0,0,0.08)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)",
  "0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)",
];

export const LIGHT_THEME = "LIGHT_THEME";
export const DARK_THEME = "DARK_THEME";
export const THEME_COLOR = "THEME_COLOR";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const DIRECTION = "DIRECTION";
export const BLUE_THEME = "BLUE_THEME";
export const GREEN_THEME = "GREEN_THEME";
export const RED_THEME = "RED_THEME";
export const BLACK_THEME = "BLACK_THEME";
export const PURPLE_THEME = "PURPLE_THEME";
export const INDIGO_THEME = "INDIGO_THEME";
export const ORANGE_THEME = "ORANGE_THEME";

const baseTheme = {
  direction: "ltr",
  palette: {
    primary: {
      main: "#1a97f5",
      light: "#e6f4ff",
      dark: "#1682d4",
    },
    secondary: {
      main: "#1e4db7",
      light: "#ddebff",
      dark: "#173f98",
    },

    success: {
      main: "#00c292",
      light: "#ebfaf2",
      dark: "#00964b",
      contrastText: "#ffffff",
    },
    danger: {
      main: "#e46a76",
      light: "#fdf3f5",
    },
    info: {
      main: "#0bb2fb",
      light: "#a7e3f4",
    },
    error: {
      main: "#e46a76",
      light: "#fdf3f5",
      dark: "#e45a68",
    },
    warning: {
      main: "#fec90f",
      light: "#fff4e5",
      dark: "#dcb014",
      contrastText: "#ffffff",
    },
    text: {
      secondary: "#777e89",
      danger: "#fc4b6c",
    },
    grey: {
      A100: "#ecf0f2",
      A200: "#99abb4",
      A400: "#767e89",
      A700: "#e6f4ff",
    },
    action: {
      disabledBackground: "rgba(73,82,88,0.12)",
      hoverOpacity: 0.02,
      hover: "rgba(0, 0, 0, 0.03)",
    },
  },

  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      color: "#949db2",
      "@media(min-width:1280px)": {
        minHeight: TopbarHeight,
        padding: "0 30px",
      },
      "@media(max-width:1280px)": {
        minHeight: "64px",
      },
    },
  },
  status: {
    danger: "#e53e3e",
  },
  components,
  typography,
  shadows,
};

const themesOptions = [
  {
    name: BLUE_THEME,
    palette: {
      primary: {
        main: "#1a97f5",
        light: "#e6f4ff",
        dark: "#1682d4",
      },
      secondary: {
        main: "#1e4db7",
        light: "#ddebff",
        dark: "#173f98",
      },
    },
  },
  {
    name: GREEN_THEME,
    palette: {
      primary: {
        main: "#00cec3",
        light: "#d7f8f6",
        dark: "#02b3a9",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#066a73",
      },
    },
  },
  {
    name: PURPLE_THEME,
    palette: {
      primary: {
        main: "#7352ff",
        light: "#e5e0fa",
        dark: "#5739d6",
      },
      secondary: {
        main: "#402e8d",
      },
    },
  },
  {
    name: INDIGO_THEME,
    palette: {
      primary: {
        main: "#1e4db7",
        light: "#e6f4ff",
        dark: "#0c399e",
      },
      secondary: {
        main: "#11397b",
      },
    },
  },
  {
    name: ORANGE_THEME,
    palette: {
      primary: {
        main: "#03c9d7",
        light: "#e5fafb",
        dark: "#05b2bd",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#fb9678",
        light: "#fcf1ed",
        dark: "#e67e5f",
        contrastText: "#ffffff",
      },
    },
  },
  {
    name: RED_THEME,
    palette: {
      primary: {
        main: "#124C8D",
        light: "#fce6ed",
        dark: "#124C8D",
        contrastText: "#fff",
      },
      secondary: {
        main: "#124C8D",
      },
    },
  },
  {
    name: BLACK_THEME,
    palette: {
      primary: {
        main: "#1c2025",
      },
    },
  },
];

export const BuildTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  const baseMode = {
    palette: {
      mode: "light",
      background: {
        default: "#fafbfb",
        dark: "#ffffff",
        paper: "#ffffff",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "#C7202F",
      },
    },
  };
  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  const theme = createTheme(
    _.merge({}, baseTheme, baseMode, themeOptions, {
      direction: config.direction,
    })
  );
  return theme;
};
